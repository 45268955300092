import { ref } from "vue";
import { useRoute } from 'vue-router'
import { QuestionCreatedRanking } from "@/types/question_created_ranking";
import { QuestionCreatedRankingRepository, GetReportParams } from '@/repositories/QuestionCreatedRankingRepository'
import { useExceptionMessage } from '@/logic/ExceptionMessageLogic'

export const QuestionCreatedRankingLogic = () => {
    const rankings = ref<QuestionCreatedRanking[]>([])
    const rankingsErrors = ref("");
    const rankingsLoading = ref(false);
    const route = useRoute();

    const getReport = async (params?: GetReportParams) => {
        if (params == undefined) {
            params = {start_date: "", end_date: ""};
        }
        rankingsLoading.value = true
        const projectId = Number(route.params.project_id);
        const repository: QuestionCreatedRankingRepository = new QuestionCreatedRankingRepository(projectId);
        try {
            rankings.value = await repository.getReport(params);
            rankingsLoading.value = false;
        } catch (e: any) {
            useExceptionMessage(e, "Q&A登録数の読み込みに失敗しました。<br>再読み込みしてお試しください");
        }
    };
    return {
        rankings,
        getReport,
        rankingsErrors,
        rankingsLoading,
    }
}


