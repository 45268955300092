import axios from "axios";
import { QuestionCreatedRanking } from "@/types/question_created_ranking";
export type GetReportParams = {
  start_date: string;
  end_date: string;
}
export class QuestionCreatedRankingRepository {
  private static readonly DOMAIN = process.env.VUE_APP_DOMAIN;
  private url: string;
  constructor(private projectId: number) {
    this.url = QuestionCreatedRankingRepository.DOMAIN + "v1/projects/" + this.projectId + "/question_created_report/";
  }
  async getReport(params: GetReportParams): Promise<QuestionCreatedRanking[]> {
    return axios.get(this.url , { params: params }).then((response) => {
      return response.data.questions;
    });
  }
}
